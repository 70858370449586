import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/srstorelogo.svg';
import './Header.css'
import React from 'react';
import '../../hooks/useComponentVisible.js'
import newLogo from '../../assets/img/newLogo.png'
import logoutIcon from '../../assets/img/logout.png';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { MainContext } from '../../DataProvider';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import ShowMessage from '../../common/ShowMessage/ShowMessage';

const Header = () => {
    const { isAuthenticated, setIsAuthenticated, message } = useContext(MainContext);
    const [showNavBar, setShowNavBar] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    

    const [displayName, setDisplayName] = useState("")

    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
       
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const checkLoggedIn = () => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                // User Signed In
                setLoggedIn(true)
            } else {
                // User is signed out
                setLoggedIn(false)
                // ...
            }
        });
    }

    useEffect(() => {
        checkLoggedIn();
        //find if logged in
        if (localStorage.getItem("user") !== null) {
            const userObj = JSON.parse(localStorage.getItem('user'));
            setDisplayName(userObj.displayName)
        }
        else {
            setDisplayName("")
        }

    }, [isAuthenticated])

    const isMobile = width <= 768;

    const switchNavToggle = () => {
        setShowNavBar(!showNavBar)
    }


    const listenScrollEvent = e => {
        if (isMobile) {
            if (window.scrollY > 50) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
        else {
            if (window.scrollY > 500) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent)
    })

    const logoutHandler = () => {
        signOut(auth).then(() => {
            console.log("logged out")
            localStorage.removeItem('user');
            localStorage.removeItem('userToken')
            setIsAuthenticated(false)
            navigate('/')
            console.log(isAuthenticated)
        }).catch((error) => {
        })
    }

    return (
        <>
            {/* <!-- Navbar Start --> */}
            <div style={{ background: scrolled ? "#FFF" : "" }} className='container-fluid fixed-top px-0 wow fadeIn headerLayout mobileStyleImage'>
                <div class="top-bar row gx-0 align-items-center d-none d-lg-flex ">
                    <div class="col-lg-6 px-5 text-start ">
                        <small><i class="fa fa-map-marker-alt me-2"></i>44, perry street, gravesend DA118RE</small>
                        <small class="ms-4"><i class="fa fa-envelope me-2"></i>info@srstores.uk</small>
                    </div>
                    <div class="col-lg-6 px-5 text-end">
                        <small>Follow us:</small>
                        <a class="text-body ms-3" target="_blank" href="https://wa.me/+447453967798"><i class="fab fa-whatsapp"></i></a>
                        <a class="text-body ms-3" target="_blank" href="https://instagram.com/srstorestheblueshop?igshid=MmJiY2I4NDBkZg=="><i class="fab fa-instagram"></i></a>
                    </div>
                </div>

                <nav class="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn responsiveImg">
                    <Link to="/" class="navbar-brand ms-4 ms-lg-0 responsiveImg">
                        <img src={newLogo} className='newLogoPlace' />
                        <img src={logo} className='logoPlace' />
                    </Link>
                    <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" onClick={switchNavToggle} data-bs-target="#navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>


                    <div class="collapse navbar-collapse" style={{ display: showNavBar ? "block" : "none" }} id="navbarCollapse">
                        <div class="navbar-nav ms-auto p-4 p-lg-0">
                            {loggedIn && <span className='welcomeuser'>Welcome {displayName}
                                <img width={20} style={{ marginLeft: '10px', cursor: 'pointer' }} src={logoutIcon} onClick={logoutHandler} />

                            </span>}
                            <Link className='nav-item nav-link' to="/">Home</Link>
                            <Link className='nav-item nav-link' to="/career">Career</Link>
                            <Link className='nav-item nav-link' to="/contactus">Contact us</Link>
                        </div>
                    </div>
                </nav>
            </div>
            {/* <!-- Navbar End --> */}
            {/* <!-- Spinner Start --> */}



            {/* <!-- Back to Top --> */}
            <a style={{ display: "flex" }} href="#" class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i class="bi bi-arrow-up"></i></a>
           {message&& <ShowMessage />}
        </>
    )
}

export default Header;