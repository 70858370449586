import { useEffect, useState } from "react";
import './ContactUs.css'
import '../../assets/lib/animate/animate.css'
import React from 'react';


const ContactUs = () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }, []);
    return (
        <>
           
                {/* <!-- Spinner End --> */}



                {/* <!-- Contact Start -->  */}
                <div class="container-xxl py-6">
                    <div class="container">
                        <div class="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                            <h1 class="display-5 mb-3 animateFromLeft">Contact Us</h1>
                            <p>Reach out to us for any inquiries, feedback, or assistance. We're here to help!</p>
                        </div>
                        <div class="row g-5 justify-content-center">
                            <div class="col-lg-5 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="text-white d-flex flex-column justify-content-center h-100 p-5 contactPane ">
                                    <h5 class="text-white">Call Us</h5>
                                    <p class="mb-5 animateFromLeft"><i class="fa fa-phone-alt me-3"></i>02035763384</p>
                                    <h5 class="text-white">Email Us</h5>
                                    <p class="mb-5 animateFromLeft"><i class="fa fa-envelope me-3"></i>info@srstores.uk</p>
                                    <h5 class="text-white">Office Address</h5>
                                    <p class="mb-5 animateFromLeft"><i class="fa fa-map-marker-alt me-3"></i>44 perry street gravesend DA118RE</p>
                                    <h5 class="text-white">Follow Us</h5>
                                    <div class="d-flex pt-2 animateFromLeft">
                                        <a class="btn btn-square btn-outline-light rounded-circle me-1" target="_blank" href="https://wa.me/+447453967798"><i class="fab fa-whatsapp"></i></a>
                                        <a class="btn btn-square btn-outline-light rounded-circle me-0" target="_blank" href="https://instagram.com/srstorestheblueshop?igshid=MmJiY2I4NDBkZg=="><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                                {/* <!-- Google Map Start --> */}
                                <div class="wow fadeIn" data-wow-delay="0.1s" style={{ marginBottom: "-6px" }}>
                                    <iframe class="w-100" style={{ height: "500px" }}
                                        src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Sr stores, gravesend&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                        frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                </div>
                                {/* <!-- Google Map End --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Contact End --> */}

        

        </>
    )
}

export default ContactUs;