
import './Career.css'
import hiringCover from '../../assets/img/hiring-7062502.jpg'
import JobItem from './JobItem';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import AddCareer from './AddCareer';
import { useState } from 'react';
import { auth, checkLoggedIn, db } from '../../firebase';
import { collection, getDocs } from "firebase/firestore";
const Career = () => {
    const [showAddPopup, setShowAddPopup] = useState(false)
    const [allJob, setAllJobs] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false)
    const [updateList, setUpdateList] = useState(false);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        getAllRecords();
        checkLoggedIn();
    }, []);

    useEffect(() => {
        console.log('loading again')
        getAllRecords();
    },[updateList])

    const checkLoggedIn = () => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                // User Signed In
                setLoggedIn(true)
            } else {
                // User is signed out
                setLoggedIn(false)
                // ...
            }
        });
    }

    const handleAddNewJob = () => {
        setShowAddPopup(true)
    }
    const getAllRecords = () => {

        getDocs(collection(db, "careeravailable"))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), id: doc.id }));
                setAllJobs(newData);
                console.log(newData);
            })

    }
    return (
        <>
            {/* <div className="hiringCover">
                <img src={hiringCover} />
            </div> */}
            {/* <!-- Carousel Start --> */}
            <div className="carouselContainer">

                <Carousel touch={true} variant="dark" fade={true} controls={false} slide={false}>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 mainImage"
                            src={hiringCover}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <div className='logoLayover'>
                                <div className="image-working-layout">
                                    <div>
                                        <div className='timeHolder animateFromLeft'>
                                            <label style={{ paddingLeft: "20px" }}>
                                                Join us in creating exceptional shopping experiences
                                                <br />contribute to creating a positive impact in the community.
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            <div className="jobListContainer container-fluid bg-light bg-icon  py-6">
                <div class="container-xxl py-6" style={{ paddingTop: "20px" }}>
                    <div class="container">
                        <div className='uploadContainer'>
                            <div><h2>Job listing</h2></div>
                            <div>
                                <a className='btn btn-lg btn-secondary rounded-pill py-3 px-5 btn-sendCV' href="mailto:info@srstores.uk">Upload your CV</a>
                            </div>
                        </div>
                        {loggedIn&&<div>
                            <span className='btn btn-lg btn-primary' onClick={handleAddNewJob}>Add new job</span>
                       
                        </div>}
                        <div>
                            <div className="jobList animateFromLeft">
                                {/* <JobItem
                                    jobTitle="Store Manager"
                                    jobLocation="gravesend DA118RE"
                                    jobDescription="Lead daily operations, drive sales, ensure customer satisfaction. Manage team, optimize inventory, maintain standards. Proven retail management, strong leadership required."
                                    availableOpening="2" />
                                <JobItem
                                    jobTitle="Store Keeper"
                                    jobLocation="gravesend DA118RE"
                                    jobDescription="Manage inventory, organize stock, ensure store operations. Maintain records, monitor levels, coordinate with suppliers. Strong organizational skills required."
                                    availableOpening="2" /> */}
                                {allJob.map((item) => {
                                    return (
                                        <JobItem
                                            jobTitle={item.jobtitle}
                                            jobLocation={item.joblocation}
                                            jobDescription={item.jobdescription}
                                            availableOpening={item.jobavailable}
                                            uid = {item.id} 
                                            setUpdateList= {setUpdateList}
                                            updateList = {updateList}
                                            isLogged={loggedIn}/>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showAddPopup && <AddCareer setShowAddPopup={setShowAddPopup} setUpdateList={setUpdateList} updateList={updateList} />}
        </>
    )
}

export default Career;