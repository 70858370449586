import React from 'react';
import './Career.css'
import { collection, deleteDoc, doc } from 'firebase/firestore';
import { MainContext } from '../../DataProvider';
import { useContext } from 'react';
import { db } from '../../firebase';
import AddCareer from './AddCareer';
import { useState } from 'react';
const JobItem = ({
    jobTitle,
    jobLocation,
    jobDescription,
    availableOpening,
    isLogged,
    uid,
    setUpdateList,
    updateList
}) => {
    const { setMessage } = useContext(MainContext);
    const [showEditPopup, setShowEditPopup] = useState(false)
    const [showConfirmPopup,setShowConfirmPopup] = useState(false)

    const handleDelete = () => {
        try {
            console.log(uid)
            const docRef = doc(db, 'careeravailable', uid);
            console.log(docRef)
            deleteDoc(docRef).then(() => {
                console.log("Document written with ID: ", docRef.id);
                setMessage("Job deleted successfully")
                setUpdateList(!updateList)
                setShowConfirmPopup(false)
            })

        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    const confirmPopup = () => {
        return (
            <>
                <div className='confirmPopup'>
                    <span>Are you sure ?</span>
                    <div className='buttonHolderConfirm'>
                        <span className='btn btn-lg btn-primary' onClick={handleDelete}>Yes</span>
                        <span className='btn btn-lg btn-secondary' onClick={()=>setShowConfirmPopup(false)}>No</span>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>

            <div className="jobItemLayout">
                <a class="close-ribbon">-</a>
                <div className="jobTitle"><label>{jobTitle}</label></div>
                <div className="jobLocation">
                    <label className="fa fa-map-marker"></label>
                    <label style={{ marginLeft: "5px" }}>{jobLocation}</label>
                </div>
                <div className="jobDescription">
                    <label>{jobDescription}</label>
                </div>
                <div className="jobAvailable">
                    <label>Available opening(s)</label>
                    <label>{availableOpening}</label>
                </div>
                {isLogged &&
                    <div className='adminTools'>
                        <i class="fas fa-edit" style={{ color: '#264478', cursor: 'pointer' }} onClick={() => setShowEditPopup(true)}></i>
                        <i class="fa fa-trash" style={{ color: '#264478', cursor: 'pointer' }} aria-hidden="true" onClick={()=>setShowConfirmPopup(true)}></i>
                    </div>
                }
            </div>
            {showEditPopup &&
                <AddCareer
                    jobTitle={jobTitle}
                    jobLocation={jobLocation}
                    jobDescription={jobDescription}
                    availableOpening={availableOpening}
                    uid={uid}
                    editMode={true}
                    setUpdateList={setUpdateList}
                    updateList={updateList}
                    setShowAddPopup={setShowEditPopup} />}

            {showConfirmPopup&& confirmPopup()}
        </>
    )
}

export default JobItem;