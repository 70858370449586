import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Career from './Layout/Career/Career';
import ContactUs from './Layout/ContactUs/ContactUs';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import HomePage from './Layout/HomePage/HomePage';
import React from 'react';
import Login from './Layout/Authentication/Login';
import { MainContextProvider } from './DataProvider';

function App() {
  return (
    <BrowserRouter>
      <div>
        <MainContextProvider>
          <Header />
          <Routes>
            <Route path="/" exact Component={HomePage} />
            <Route path="/career" exact Component={Career} />
            <Route path="/contactus" exact Component={ContactUs} />
            <Route path="/loginuser" exact Component={Login} />
          </Routes>
          <Footer />
        </MainContextProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
