// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


export const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

const firebaseConfig = {
    apiKey: "AIzaSyDPh9gRwlvzWTjqsEkm6QgXwn_zUVTRd70",
    authDomain: "srstoresuk.firebaseapp.com",
    databaseURL: "https://srstoresuk-default-rtdb.firebaseio.com",
    projectId: "srstoresuk",
    storageBucket: "srstoresuk.appspot.com",
    messagingSenderId: "966330771831",
    appId: "1:966330771831:web:d89a66f94257b5845e1972",
    measurementId: "G-X05EXDZT7F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore();
export const auth = getAuth();





