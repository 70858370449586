import '../../assets/css/style.css';
import React from 'react';
import '../../assets/css/bootstrap.min.css';
// import '../../assets/lib/animate/animate.css'
import Carousel from 'react-bootstrap/Carousel';
import './Homepage.css';

import { useEffect, useState } from 'react';
import '../../assets/lib/animate/animate.css'
import { Link } from 'react-router-dom';
import ProductTile from './ProductTile';

import vape from '../../assets/img/vapeprod.png'
import alchohol from '../../assets/img/alchohol.jpg'
import beer from '../../assets/img/beer.jpg'
import { Carousel as CarouselThree } from '3d-react-carousal';
import topPicture from '../../assets/img/carousel-1.jpg'

const HomePage = () => {
    const [listOfImages, setListOfImages] = useState([])
    const [showSpinner, setShowSpinner] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        hideSpinner();
        setListOfImages(importAll(require.context('../../assets/img/slideshow/', false, /\.(png|jpe?g|svg)$/)));
    }, [])
    const hideSpinner = () => {
        setTimeout(
            function () {
                setShowSpinner(false)
            }
                .bind(this),
            100
        );
    }
    const importAll = (r) => {

        return r.keys().map(r);
    }
    const getListOfImagesInImg = () => {
        let finalList = []
        listOfImages.map((item) => {
            finalList.push(<img className='image-slideshow-three' src={item} alt="1" />)
        })
        return finalList;
    }
    return (

        <>
            {showSpinner &&
                <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                    <div class="spinner-border text-primary" role="status"></div>
                </div>
            }
            {/* <!-- Spinner End --> */}



            {/* <!-- Carousel Start --> */}
            <div className="carouselContainer">

                <Carousel touch={true} variant="dark" fade={true} controls={false} slide={false}>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 mainImage"
                            src={topPicture}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <div className='logoLayover animateFromLeft'>
                                <div className="image-working-layout">
                                    <label className="messageHead">Mark Your Calendars</label>
                                    <label className='messageContent'>Grand Opening of Our New Branch Just Around the Corner!</label>

                                </div>

                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            {/* <!-- Carousel End --> */}

            {/*Working hours */}




            {/* <!-- Firm Visit Start --> */}
            <div class="container-fluid bg-theme bg-icon mt-5 py-6">
                <div class="container">
                    <div class="row g-5 align-items-center">
                        <div class="col-md-7 wow fadeIn">
                            <h1 class="display-5 text-white mb-3">About</h1>
                            <p class="text-white mb-0">
                                Welcome to S R Stores, where we offer a wide selection of premium beverages and quality grocery items. Our knowledgeable team is dedicated to providing exceptional service and helping you find the perfect products. We are currently hiring! If you are looking for a job, click the button below to explore our employment opportunities. Visit us for a unique shopping experience that exceeds expectations.
                            </p>
                        </div>
                        <div class="col-md-5 text-md-end wow fadeIn">
                            <Link to="/career" class="btn btn-lg btn-secondary rounded-pill py-3 px-5 btn-showMe" style={{ background: "#FCFCFC", border: "none", color: "#555555" }} href="">Career</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Firm Visit End --> */}




            {/* <!-- Testimonial Start --> */}
            <div class="container-fluid bg-light bg-icon py-6 mb-5">
                <div class="container">
                    <div class="section-header text-center mx-auto mb-5 wow fadeInUp" style={{ maxWidth: "500px" }}>
                        <h1 class="display-5 mb-3">Operating Hours</h1>
                    </div>
                    <div class="owl-carousel testimonial-carousel wow fadeInUp">
                        <div class="testimonial-item position-relative bg-white p-5 mt-4">
                            <div>
                                <div className='timeHolder'>
                                    <i class="fa fa-calendar" style={{ color: "#0F4B92" }} ></i><label >Sunday - Thursday</label><label className='actualTime'>07:00 - 00:00</label>
                                </div>
                                <div className='timeHolder'>
                                    <i style={{ color: "#0F4B92" }} class="fa fa-calendar"></i><label>Friday - Saturday</label><label className='actualTime'>07:00 - 01:00</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Testimonial End --> */}


            {/* <!-- Blog Start --> */}
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="section-header text-center mx-auto mb-5 wow fadeInUp" style={{ maxWidth: "500px" }}>
                        <h1 class="display-5 mb-3">Explore Our Store</h1>
                        <p>Step inside and experience the inviting ambiance of S R Stores</p>
                    </div>
                    <div class="row g-4">

                        <CarouselThree slides={getListOfImagesInImg()} autoplay={true} interval={10000000} />
                    </div>
                </div>
            </div >
            {/* <!-- Blog End --> */}


            {/* <!-- Feature Start --> */}
            <div class="container-fluid bg-light bg-icon my-5 py-6">
                <div class="container">
                    <div class="section-header text-center mx-auto mb-5 wow fadeInUp" style={{ maxWidth: "500px" }}>
                        <h1 class="display-5 mb-3">Our Products</h1>
                        <p>Explore our standout products, each crafted to deliver quality and satisfaction</p>
                    </div>
                    <div class="wow fadeInUp productList" data-wow-delay="0.5s">
                        <ProductTile image={vape} productTitle="Vape" />
                        <ProductTile image={alchohol} productTitle="Alcohol" />
                        <ProductTile image={beer} productTitle="Beer" />
                    </div>
                </div>
            </div>
            {/* <!-- Feature End --> */}





        </>
    )
}

export default HomePage;