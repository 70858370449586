import React, {useState} from "react"

const MainContext = React.createContext();

function MainContextProvider({children}) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [message, setMessage] = useState("")

    return (
        <MainContext.Provider value={{isAuthenticated, setIsAuthenticated, message, setMessage}}>
            {children}
        </MainContext.Provider>
    )
}

export {MainContextProvider, MainContext}