import React from "react";
import hiringCover from '../../assets/img/carousel-login.jpg'
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from "react-router-dom";
import './Login.css'
import { signInWithPopup, GoogleAuthProvider, signOut, signInWithRedirect, getRedirectResult } from "firebase/auth";

import googleSign from '../../assets/img/googlesign.png';
import { auth, db, provider } from "../../firebase";
import { useContext } from "react";
import { MainContext } from "../../DataProvider";
import { collection, getDocs } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";

const Login = () => {
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated } = useContext(MainContext);
    const [errorMessage, setErrorMessae] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);

    const checkLoggedIn = () => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                // User Signed In
                setLoggedIn(true)
            } else {
                // User is signed out
                setLoggedIn(false)
                // ...
            }
        });
    }

    useEffect(() => {
        checkLoggedIn();
        initApp();
    }, [])
    useEffect(() => {
        if (loggedIn) {
            navigate('/')
        }
    }, [loggedIn])

    function initApp() {
        // Result from Redirect auth flow.
        getRedirectResult(auth).then(function (result) {
            if (result && result.user) {
                const user = result.user;
                if (!(user.email === 'srstores143@gmail.com' || user.email === 'info@srstores.uk'|| user.email === 'srstoresperry@gmail.com'))
                    //delete user if the user is new
                    user.delete().then(() => {
                        signOut(auth).then(() => {
                            setErrorMessae("You are not authorized to login")
                            setIsAuthenticated(false)
                        })
                    });
                else {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    // const credential = GoogleAuthProvider.credentialFromResult(result);
                    // const token = credential.accessToken;
                    // The signed-in user info.
                    //const user = result.user;
                    // IdP data available using getAdditionalUserInfo(result)

                    //localStorage.setItem('userToken', token)
                    const userObj = {
                        "displayName": user.displayName,
                        "userEmail": user.email,
                        "emailVerified": user.emailVerified,
                        "photoUrl": user.photoURL,
                        "uid": user.uid
                    }
                    localStorage.setItem('user', JSON.stringify(userObj));
                    setIsAuthenticated(true)
                    navigate('/career')
                }
            }
        });
    }
    const signWithGoogle = () => {
        signInWithRedirect(auth, provider);
    }



    return (
        <>
            <div className="carouselContainer" style={{ height: '400px' }}>

                <Carousel touch={true} variant="dark" fade={true} controls={false} slide={false}>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 mainImage"
                            src={hiringCover}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <div className='logoLayover'>
                                <div className="image-working-layout">
                                    <div>
                                        <div className='timeHolder animateFromLeft'>
                                            <label style={{ paddingLeft: "20px" }}>
                                                Login to explore more
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            <div className="jobListContainer container-fluid bg-light bg-icon  py-6">
                <div class="container-xxl py-6" style={{ paddingTop: "20px" }}>
                    <div class="container">
                        <div className='uploadContainer'>
                            <div><h2>Authenticate your identity</h2></div>
                        </div>
                        <div>
                            <div className="jobList animateFromLeft">
                                <img style={{ cursor: 'pointer' }} src={googleSign} onClick={signWithGoogle} />
                            </div>
                        </div>
                        <div>
                            <span style={{ color: 'red', marginLeft: '50px' }}>{errorMessage}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;