import { Link } from 'react-router-dom';
import logo from '../../assets/img/logowhite.svg';
import React  from 'react';

const Footer = () => {
    return (
        <>
            {/* <!-- Footer Start --> */}
            <div class="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn">
                <div class="container py-5">
                    <div class="row g-5">
                        <div class="col-lg-3 col-md-6">
                            <img width="250" src={logo} />
                            <p style={{paddingTop: "10px"}}>Welcome to S R Stores, where we offer a wide selection of premium beverages and quality grocery items. Our knowledgeable team is dedicated to providing exceptional service and helping you find the perfect products. </p>
                            <div class="d-flex pt-2">
                                <a class="btn btn-square btn-outline-light rounded-circle me-1" target="_blank" href="https://instagram.com/srstorestheblueshop?igshid=MmJiY2I4NDBkZg=="><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-square btn-outline-light rounded-circle me-1" target="_blank" href="https://wa.me/+447453967798"><i class="fab fa-whatsapp"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <h4 class="text-light mb-4">Address</h4>
                            <p><i class="fa fa-map-marker-alt me-3"></i>44 perry street, gravesend DA118RE</p>
                            <p><i class="fa fa-phone-alt me-3"></i>02035763384</p>
                            <p><i class="fa fa-envelope me-3"></i>info@srstores.uk</p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <h4 class="text-light mb-4">Quick Links</h4>
                            <Link className='btn btn-link' to="/career">Career</Link>
                            <Link className='btn btn-link' to="/contactus">Contact us</Link>
                        </div>
                        {/* <div class="col-lg-3 col-md-6">
                            <h4 class="text-light mb-4">Newsletter</h4>
                            <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                            <div class="position-relative mx-auto" style={{ maxWidth: "400px" }}>
                                <input class="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                                <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div class="container-fluid copyright">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <Link to="/">SR Stores</Link>, All Right Reserved.
                            </div>
                            {/* <div class="col-md-6 text-center text-md-end">
                                {/* <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. 
                                Designed By <a href="https://htmlcodex.com">HTML Codex</a>
                            </div> 
                            */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}
        </>
    )
}

export default Footer;