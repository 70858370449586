import React from "react";
import './AddCareer.css'
import { useState } from "react";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase';
import { useContext } from "react";
import { MainContext } from "../../DataProvider";
import { useEffect } from "react";

const AddCareer = ({
    setShowAddPopup,
    editMode,
    jobTitle,
    jobLocation,
    jobDescription,
    availableOpening,
    uid,
    setUpdateList,
    updateList
}) => {
    const { setMessage } = useContext(MainContext);
    const [formdata, setformdata] = useState({
        jobtitle: "",
        joblocation: "",
        jobdescription: "",
        jobavailable: ""
    })
    useEffect(() => {
        if (editMode) {
            setformdata({
                jobtitle: jobTitle,
                joblocation: jobLocation,
                jobdescription: jobDescription,
                jobavailable: availableOpening
            })
        }
    }, [])
    const handleChange = (e) => {
        e.preventDefault();
        setformdata({ ...formdata, [e.target.name]: [e.target.value] })
    }

    const addNewCareer = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (editMode) {
            try {
                const docRef = doc(db, 'careeravailable', uid);
                console.log(docRef)
                updateDoc(docRef, {
                    ...formdata
                }).then(() => {
                    console.log("Document written with ID: ", docRef.id);
                    setMessage("Job updated successfully")
                    setUpdateList(!updateList)
                    handleClose();
                })

            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }
        else {
            try {
                const docRef = addDoc(collection(db, "careeravailable"), {
                    ...formdata
                });
                console.log("Document written with ID: ", docRef.id);
                setMessage("Job added successfully")
                setUpdateList(!updateList)
                setShowAddPopup(false);

            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }
    }
    const handleClose = () => {
        setShowAddPopup(false)
    }
    return (
        <>
            <div className="addContainer">
                <div className="addTitle">
                    <span >Add new job</span>
                </div>
                <div>
                    <div><input class="form-control" type="text" name="jobtitle" placeholder="Job Title" value={formdata.jobtitle} onChange={handleChange} /></div>
                    <div><input class="form-control" type="text" name="joblocation" placeholder="Job Location" value={formdata.joblocation} onChange={handleChange} /></div>
                    <div><input class="form-control" type="textarea" name="jobdescription" placeholder="Job Description" value={formdata.jobdescription} onChange={handleChange} /></div>
                    <div><input class="form-control" type="number" name="jobavailable" placeholder="Available vacancy" value={formdata.jobavailable} onChange={handleChange} /></div>
                </div>
                <div className="button-container">
                    <button onClick={addNewCareer} class="btn btn-lg btn-secondary rounded-pill">
                        {editMode ? 'Update' : 'Add'}
                    </button>
                    <button onClick={handleClose} class="btn btn-lg btn-secondary rounded-pill">Close</button>
                </div>
            </div>
        </>
    )
}

export default AddCareer;