import React from "react";
import './ShowMessage.css'
import { MainContext } from "../../DataProvider";
import { useContext } from "react";
import { useEffect } from "react";

const ShowMessage = () => {
    const { message, setMessage } = useContext(MainContext);
    useEffect(() => {
        setTimeout(() => {
            setMessage("");
        }, 2000);
    }, [message])
    return (
        <>
            <div className="messageContainer">
                {message}
            </div>
        </>
    )
}

export default ShowMessage;