
import './ProductTile.css'
import React  from 'react';
const ProductTile = ({
    image,
    productTitle
}) => {
    return (
        <>
            <div class="product-item productTile">
                <div class="position-relative bg-light overflow-hidden imageHolder">
                    <img class="img-fluid w-100" src={image} alt="" />
                    {/* <div class="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div> */}
                </div>
                <div class="text-center p-4">
                    <label class="d-block h5 mb-2">{productTitle}</label>
                    {/* <span class="text-primary me-1">$19.00</span>
                    <span class="text-body text-decoration-line-through">$29.00</span> */}
                </div>
            </div>
        </>
    )
}

export default ProductTile;